import React, {useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button, Form, Nav, Navbar, Alert, Badge, FloatingLabel, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/AuthProvider';
import axios from 'axios';
import UserSurvey from './usersurvey.component';
import { prefixes, canadianUniversitiesByProvince, canadianUniversities, selectOptions, compareEventDate } from '../../../tools/main';
import { Slack } from 'react-bootstrap-icons';

function unixToDate(timeStamp) {
    if (timeStamp != null) {
        // console.log("timeStamp:", timeStamp)
        const date = new Date(timeStamp)
        // console.log(date)
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const dateString = date.toLocaleString('en-US', options);
        
        return dateString; 
    } else {
        return "Not a Member"
    }

}

function displayPlainTxt (variableTitle, variableValue) {

    return (
        <Form.Group as={Row} className="mb-1" controlId="formPlaintextEmail">
        <Form.Label column xs="6" sm="4" style={{ }}>
          { variableTitle }
        </Form.Label>
        <Col xs="6" sm="8" >
          <Form.Control style={{ textAlign: 'center'}} plaintext readOnly defaultValue= { variableValue } />
        </Col>
      </Form.Group>
    )
}


function MembersDashboard () {

    return (
        <>  
            
            <Card.Body >
                <h5> Member's Dashboard </h5>

    
                <ul style={{ padding: "16px"}}>
                    <li> <a href="https://join.slack.com/t/csam-scma/shared_invite/zt-2kzrhwkg4-pnmB5IuiekpNGwykh~qeuA"> Member's Slack Workspace </a> <Slack /> </li>
                    <li>  <a href="https://www.royalcollege.ca/en/credentials-exams/exam-eligibility/areas-focussed-competence-afc-diploma/apply-afc-practicing.html"> Practice Eligibility Route - AFC </a> </li>
                </ul>
            </Card.Body>
        </>
    )
} 


function UpdateProfile({}) {
    const navigate = useNavigate()
    const [successMessage, setSuccessMessage] = useState('')
    const [errMessage, setErrMessage] = useState('')
    const {auth, setAuth} = useContext(AuthContext)


    const [lastName, setLastName] = useState(auth?.lastname)
    const [firstName, setFirstName] = useState(auth?.firstname)
    const [prefix, setPrefix] = useState(auth?.prefix)    
    const [country, setCountry] = useState(auth.address?.country)
    const [city, setCity] = useState(auth.address?.city)
    const [province, setProvince] = useState(auth.address?.province)
    const [street1, setStreet1] = useState(auth.address?.street1)
    const [street2, setStreet2] = useState(auth.address?.street2)
    const [postalCode, setPostalCode] = useState(auth.address?.postalCode)


    // const [address, setAddress] = useState(auth.address == null? ({
    //     country: '',
    //     city: '',
    //     province: '',
    //     street1: '',
    //     street2:'',
    //     postalCode: ''
    //   }):(auth.address));


    const handleUpdateProfile = async (e) => {
        e.preventDefault()

        const filter = { email: auth.email }
        const newAdress = { street1, street2, city, province, country, postalCode }
        const form_data = { prefix, firstName, lastName,
            displayName: firstName + ' ' + lastName,
            address: newAdress
        }

        console.log(form_data)
        axios.post('/user/update-profile', { filter, form_data}).then(res => {
            if (res.data.success) {
                //set success message

                setSuccessMessage("Done! your profile was successfully updated")

                //update auth
                let updateAuth = {...res.data.user} //create a copy

                updateAuth.loggedIn = true
                updateAuth.accessToken = res.data.accessToken

                setAuth(updateAuth)

                //update token
                localStorage.setItem("accessToken", res.data.accessToken)
            } else {
                //set error message that updates could not be down
                setErrMessage("We could not update your profile. Please try again at a later time.")
            }
        }).catch(err=> {
            // set error that backend could not update data
            setErrMessage("We could not update your profile. Please try again at a later time.")
        }) }
    return (
        <>

                <Card.Body>
                    {
                        successMessage != '' ? (<Alert variant="success"> {successMessage} </Alert>):(<></>)
                    }
                        <Form>
                            <Form.Label> <h5> Update profile </h5></Form.Label>   
                            <Form.Group className='mt-1'>
                                <FloatingLabel label="Prefix">
                                    <Form.Select onChange= {e => setPrefix(e.target.value)} >
                                        <option value={ auth.prefix } > { auth.prefix }</option>
                                        {
                                            prefixes.map(name => {
                                                if (name != auth.prefix) {
                                                    return <option value={ name } > {  name }</option>
                                                }
                                                
                                            })             
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group  className='form-group mt-3' >
                                                                
                                <Row>
                                    <Col>
                                        <Form.Control 
                                            type="text" 
                                            value={firstName}
                                            onChange={e=>setFirstName(e.target.value)}
                                            placeholder='First Name'
                                        />
                                    </Col>

                                    <Col>
                                        <Form.Control 
                                            type="text" 
                                            value={lastName}
                                            onChange={e=>setLastName(e.target.value)}
                                            placeholder='Last Name'
                                            />
                                    </Col>
                                </Row>

                            </Form.Group>

                            
                            <Form.Group className='form-group mt-3 mb-3' >
                                <Form.Label>Address</Form.Label>

                                <Row className="mt-1">
                                    <Col>
                                        <Form.Control 
                                            type="text" 
                                            value={street1} 
                                            onChange={e=> {setStreet1(e.target.value) }} 
                                            placeholder='Address Line 1'/>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Form.Control 
                                            type="text" 
                                            value={street2} 
                                            placeholder='Address Line 2 (e.g., Apartment unit, suite, floor, building,department numbers, or PO Boxes.)'
                                            onChange={e=> {setStreet2(e.target.value) }} 
                                            />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Form.Control 
                                            type="text" 
                                            value={city} 
                                            onChange={e=> {setCity(e.target.value) }} 
                                            placeholder='City'/>
                                        </Col>
                                    <Col>
                                        <Form.Control 
                                            type="text" 
                                            value={province}
                                            onChange={e=> {setProvince(e.target.value) }}  
                                            placeholder='Province/State'/>
                                    </Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Form.Control 
                                        type="text" v
                                        value={country} 
                                        onChange={e=> {setCountry(e.target.value) }} 
                                        placeholder='Country' />
                                    </Col>
                                    <Col>
                                        <Form.Control 
                                            type="text" 
                                            value={postalCode} 
                                            onChange={e=> {setPostalCode(e.target.value) }} 
                                            placeholder='Postal/Zip code'/>
                                    </Col>
                                </Row>
            
                            
                            </Form.Group>
                            
                            
                            

                            {/* <Form.Group className='mt-2'>
                                
                                <Form.Label>Additional Information </Form.Label>
                                    < UserSurvey /> 

                                    </Form.Group>*/}

                            
                            <Form.Group className="mt-2" style={{ textAlign: "center"}}>
                                <Button  
                                    variant="warning" 
                                    onClick={ e=> handleUpdateProfile(e)} 
                                    id="update-profile-btn" 
                                    className="round-btn" > Update </Button>
                            </Form.Group>
                    </Form>
                    
                </Card.Body>
        </>
    )
}

function MyAccount ({auth}) {
    const navigate = useNavigate()
    return (     
        <Card.Body className="myaccount" >
            <h5> Account Details  </h5>
            { displayPlainTxt("Name", auth.displayname )}
            { displayPlainTxt("CSAM ID", auth.userid)}
            { displayPlainTxt("Email", auth.email )}
            <br />
            <h5> Membership Details  </h5>
            { displayPlainTxt("Membership Type", auth.membershiptype )}
            { displayPlainTxt("Membership Status", auth.membershipstatus )}
            { displayPlainTxt("Activation Date", unixToDate(auth.membershipactivationdate) )}
            { displayPlainTxt("Expiration Date", unixToDate(auth.membershipexpirationdate) )}

                <div className= { auth.membership_is_expired != 'true'? ("hide"):("show")}>
                    <Alert variant='danger'>
                        <p>
                            Dear Member,
                        </p>
                        
                        <p>
                            We would like to inform you that your membership had expired on <strong> {unixToDate(auth.membershipexpirationdate) } </strong>. 
                            However, we are pleased to extend your membership until July 15 
                            as a gesture of our appreciation. Please note that if your membership 
                            is not renewed by <strong> July 15, 2024</strong>, it will unfortunately be <strong> canceled. </strong> 
                            Thank you for your understanding !
                        </p>

                        <p>
                            You can renew your membership here: <a href='/signup-membership'> Membership renewal </a>
                        </p>

                        <p>
                            Sincerely, <br/>
                            CSAM WEBTEAM
                        </p>
                    </Alert>
                </div>
            <div className="mt-3" style={{ textAlign: "center"}}>
                { auth.ismember === "true" ? (
                <></>
                ):(<Button  variant="warning" className="btn-become-member"onClick={ (e) => { navigate("/signup-membership")}}> Get Membership Access </Button>)}
            </div>
        </Card.Body>

    )
}

function UserBoard ({auth, currUserView}) {
    const navigate = useNavigate()
    // const [currUserView, setUserView] = useState('')
    
    return (
        <Row id="userboard">
            <Card className='userboard-card'>
                {/* <Card.Header>
                    <Navbar>
                        <Nav>
                            <Nav.Link onClick = {e=>setUserView('')}> My Account </Nav.Link>
                            <Nav.Link onClick = {e=>setUserView('update-profile')}> Update Profile </Nav.Link>
                        </Nav>
                    </Navbar>
                </Card.Header> */}
                
                <div className={currUserView == '' ? "show":"hide"}> 
                    < MyAccount auth={auth} />
                </div>

                <div className={currUserView == 'update-profile' ? "show":"hide"}>
                    <UpdateProfile auth={auth} />
                </div>
                
                <div className= {auth.ismember == 'true' ? ('show'):('hide')}>
                    <div className={currUserView == 'members-view' ? "show":"hide"}>
                        <MembersDashboard />
                    </div>
                </div>

                    
            </Card>
        </Row>
    )
}
 export default function UserAccount() {
    const {auth, setAuth} = useContext(AuthContext)
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [currUserView, setUserView] = useState('')


    useEffect(()=> {
        // console.log(auth)

    },[auth])

    useEffect(()=> {
        didMount(true)
    }, [])

    useEffect(()=> {
        if (isMounted) {
            isLoading(false)
        }
    },[auth, isMounted])



    if (!loading) return(
        <Container>
           <Row className='mt-100'>
                <Col></Col>
                <Col xs={10}>
                    <Row style={{ height: ""}}>
                        <Col xs={10} sm={4} className="mx-auto mb-2">
                            <Card  className="">
                                <Card.Body style={{ padding: "2px"}}>
                                    {/* <Image className='user-profile' roundedCircle src="https://cdn.dribbble.com/users/6142/screenshots/5679189/profiledefault_4x.png" alt='' /> */}
                                    <Card.Img className="" variant='top' src="https://cdn.dribbble.com/users/6142/screenshots/5679189/profiledefault_4x.png" />
                                    
                                    <Card.Text className="user-info"> 
                                        <h5>
                                            {auth.prefix == null? (auth.displayname):( auth.prefix + ' ' + auth.displayname)}
                                        </h5>
                                        
                                        <small>                               
                                            <Row > 
                                                <Col xs={3} style={{paddingRight: "6px"}}> ID </Col>
                                                <Col xs={9} style={{}}>  {auth.userid} </Col>
                                            </Row>
                                        </small>
                                    </Card.Text>
                                </Card.Body>
                                
                            </Card>

                            <ListGroup className='mt-2'>
                                <ListGroup.Item onClick = {e=> setUserView('')} className='li-group-btn' id=''> My Account </ListGroup.Item>
                                <ListGroup.Item onClick = {e=> setUserView('update-profile') } className='li-group-btn' id='update-profile'> Update Profile </ListGroup.Item>
                                <ListGroup.Item onClick = {e=> setUserView('members-view')} className= {auth.ismember == 'true' ? ('show li-group-btn'):('hide')} id ='members-resources'> Membership </ListGroup.Item>
                            </ListGroup>

                        </Col>
                        <Col xs={10} sm={8} className="mx-auto mb-2" >
                            < UserBoard auth={ auth } currUserView={ currUserView }/>
                        </Col>

                    </Row>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col xs={10}>
                    <Row>
                            <Col xs={10} md={3} className="mx-auto mb-2"></Col>
                        <Col xs={10} md={7}  className="mx-auto mb-2">
                        </Col>
                        <Col xs={10} md={2} className="h100 m-auto" ></Col>
                    </Row>
                </Col>
                <Col></Col>

            </Row>
        </Container>
        
    )
}