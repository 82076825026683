import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Button, Modal, Image, ListGroup, Carousel } from 'react-bootstrap';
import { Calendar, Lightbulb, People, Compass, Plus, Newspaper, CalendarWeek, BoxArrowInUpRight, Globe2, Clock } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import bg_img from '../../images/csam-bg.jpeg'


import { Swiper, SwiperSlide } from "swiper/react";
import KeenSlider from 'keen-slider'

import EmbedSlack from '../widgets/slack/slack.widget.component';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";


// import required modules
import { Autoplay, Pagination, Navigation, EffectCoverflow } from "swiper";
import { height } from '@mui/system';

import "../main-style.css"

import CSAM_WELCOME_IMAGE from './images/CSAM-wordmark-colour.jpg'
import DK_IMAGE from './images/DK_ROUNDS.png'
import axios from 'axios';
import CsamCalendar from '../calendar/csam-calendar';


export function HeroComponent() {
    const navigate = useNavigate()
    return (
        <>
            <Row id="home-wrapper" >
                <div id="home-wrapper-col" className="overlay"> 
                    <Row className='h100'>
                        <Col></Col>
                        <Col className='h100' style={{ textAlign: "center"}} xs={10} sm={6} >
                            {/* { homeSlides() } */}
                                <div className='home-banner-container fade-in'>
                                    <div className='home-banner-text'>
                                        <h1 style={{ fontWeight: "700"}}>The Canadian Society of Aerospace Medicine (CSAM) </h1>
                                        <h4 style={{ textAlign: "center", fontFamily: "Times New Roman" }}>
                                            The official organization representing aerospace medicine professionals in Canada.
                                        </h4>

                                        
                                            <Button variant="outline-warning" className='home-btn shadow-lg' onClick={ (e)=> navigate("/about") } >
                                                ABOUT US
                                            </Button>

                                            <Button  variant="outline-warning"  className='home-btn shadow-lg' onClick={ (e)=> navigate("/membership") }>
                                                MEMBERSHIP
                                            </Button>
                                        
                                    </div>

                                </div>            
                        </Col>
                        <Col></Col>
                    </Row>
                </div>
            </Row>
            
        </>
    )

}

export function CsamObjectives () {
    const goals_array = []
    return (
            <>
               
                <Col className='mb-1 goals-col' xs={4}> 
                    <Card style={{fontFamily: "Times New Roman"}} className='goals-card' >
                                    <div>
                                        <Lightbulb className='goals-icon' />
                                        <h4 className='goals-header-text' >Advocate</h4>
                                    </div>
                                    <Card.Text className='goals-card-text'>
                                        

                                        <p className='goals-body-text'>
                                            To promote aerospace medicine in Canada through education, outreach, and collaboration.
                                        </p>
                                        
                                    </Card.Text>
                    </Card>
                </Col>
                <Col className='mb-1 goals-col' xs={4}> 
                            <Card className='goals-card'>
                                <div>
                                    <Globe2 className='goals-icon'  />
                                    {/* <Compass viewBox="0 0 22 22" className='goals-icon' /> */}
                                </div>
                                <Card.Text className='goals-card-text'>
                                    <h4  className='goals-header-text'> Hub </h4>
                                    
                                    <p className='goals-body-text'>
                                        To be a central point 
                                        for Canadian aerospace medicine across industry, 
                                        government and academic sectors.
                                    </p>
                                    
                                </Card.Text>
                            </Card>
                </Col>
                <Col className='mb-1 goals-col' xs={4}> 
                         <Card className='goals-card'>
                                <div>
                                    <People className='goals-icon' />
                                </div>
                                <Card.Text className='goals-card-text'>
                                    <h4  className='goals-header-text'> Network </h4>
                                    <p className='goals-body-text'>
                                        To foster a supportive network of aerospace medicine professionals in Canada.
                                    </p>
                                </Card.Text>           
                            </Card>
                </Col>
            </>
    )
}
export default function Home() {
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [events, setEvents] = useState([])

    
    const news_array = [{
        title: "Cool job: Dr. Joelle Thorgrimson, FP and flight surgeon, Royal Canadian Air Force",
        description: "Thinking you might want to try something new? In this 'Cool Jobs' series, we highlight some inspiring Canadian physicians who are doing something a little different",
        img: 'https://assets1.canadianhealthcarenetwork.ca/styles/max_width_480/s3/2023-10/cool_job_thorgrimson.jpg?itok=G5tAH47V',
        source_url: 'https://www.canadianhealthcarenetwork.ca/cool-job-dr-joelle-thorgrimson-fp-and-flight-surgeon-royal-canadian-air-force'
    },{
        title: "Zero Gravity, Zero Gain: The Genetics Behind Muscle Loss in Space",
        description: "Simulated microgravity in mice reveals a constellation of genes that may influence muscle weakening during spaceflight. -- “Zero Gravity, Zero Gain: The Genetics Behind Muscle Loss in Space” (The Scientist Magazine)",
        img: "https://cdn.the-scientist.com/assets/articleNo/71612/hImg/52033/65248-ts-istock-resize-spaceman-1800x720-x.webp",
        source_url: 'https://www.the-scientist.com/news/zero-gravity-zero-gain-the-genetics-behind-muscle-loss-in-space-71612#:~:text=Reporting%20in%20npj%20Microgravity%2C%20Donahue,whether%20genes%20influence%20muscle%20loss.&text=They%20found%20that%20some%20mice,disrupted%20expression%20across%20all%20strains.'
    }]
    useEffect(()=> {
        
        let tempSlides = []
        // get list of events
        const getEvents = async ()=>{
            const response = await axios.post("/event/all").then(res => {
                // console.log(res.data)
                if (res.data.success) {
                    return res.data.events
                } else {
                    return null
                }
            }).catch(err=>{
                console.log(err)
                return null
            })

            return response
        }

       
        getEvents().then(res =>{
            if (res!= null) {
                // console.log(res)
                setEvents(res)
                didMount(true)
    
            }
    
        })



    }, [])

    useEffect(()=> {
        if (isMounted) {
            isLoading(false)
        }
    },[isMounted])

    useEffect(()=> {

    }, [loading])

    const handleExpand = (e, target_element) => {
        // 
        e.preventDefault()

        const el = document.getElementById(target_element)

        if ( el.classList.contains('show') ) {
            el.classList.remove('show')
        } else {
            el.classList.add('show')
        }

    }
    


    return (
    <Container fluid id="home-container">
    {/* Home Banner */}
    <HeroComponent />

    <Row id="home-body">
        <Col></Col>
        <Col xs={12} sm={10} md={8} xxxl={6}>
            <Row className='row-csam-objectives'>
                <CsamObjectives />
            </Row>



            {/* News Row */}

            <Row className='row-stn'>
                
                <div className='row-title'>
                        <h5>Latest</h5>
                </div>

                <Col xs = {8} sm={4} className='news-col'>
                    <Card  className="news-card" style={{ padding: "0px", }}>
                        <iframe 
                        id="title-1"
                        style={{borderRadius:"12px"}}
                        src="https://open.spotify.com/embed/episode/0KcmmkTuZbnQgktRaDlP5x?utm_source=generator" 
                        width="100%" 
                        height="250" 
                        // allowfullscreen="" 
                        // allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        // loading="lazy">
                        >
                        </iframe>
                        <Card.Body style={{}}>
                        
                        <Card.Text >
                        <h6>The Aerospace Medicine Podcast</h6>
                        Hear from the pioneers who founded CSAM as they 
                        share their career journeys and insights in aerospace medicine.                </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                {news_array.map((news, index) => {
                                if (index <= 3) {
                                    return (
                                        <Col xs = {8} sm={4} key={index} className='news-col'>
                                            <Card className="news-card" onClick={e=> { window.location.href = news.source_url }} >
                                                <Card.Img variant='top' className='news-img' src={news.img}></Card.Img>
                                                <Card.Body>
                                                    <h6>{news.title}</h6>
                                                    <p>{news.description}</p>
                                                </Card.Body>
                                            </Card>

                                        </Col>
                                    );
                                } else {
                                    return null;
                                }
                            })}
            </Row>
            {/* Home Events Row */}
            {loading === false && (
                <Row id="csam-event" className='row-stn'>
                    <Row style={{justifyContent: "center" }}>
                        <div className='row-title'>
                            <h5>Events</h5>
                        </div>
                        
                            {events.map((x, index) => {
                                if (index < 4) {
                                    return (
                                        <Col xs={8} sm={3}  style={{ paddingLeft: "2px" }} key={index}>
                                            <Card className='event-card' onClick={e => { navigate('/event-view/' + x.poster_url) }} >
                                                <div className=''>
                                                    <Card.Img className='event-image' variant="top" src={window.location.origin + '/event/images/' + x.poster_url} />
                                                </div>
                                                <Card.Body style={{ backgroundColor: "red!important", padding: "1rem 0.3rem 0 0.3rem" }}>
                                                    <Card.Title className='event-title'>{x.title}</Card.Title>
                                                    
                                                    <Card.Text className='event-text'>
                                                        <Clock /> {x.date}
                                                        <br />
                                                        {x.description}
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer style={{
                                                    padding: "7px",
                                                    backgroundColor: "#fff",
                                                    borderTop: "0px"
                                                }}>

                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                    </Row>
                </Row>
            )}
        </Col>
        <Col></Col>
    </Row>
</Container>

    )
}


