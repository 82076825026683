import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

export default function SingleEvent() {
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [eventKey, setEventKey] = useState(null)
    const [event, setEvent] = useState(null)



    useEffect(()=> {
        const pathname = window.location.pathname;
        // console.log(pathname, parts)
        const parts = pathname.split("/")
        const key = parts[parts.length - 1]
        // console.log(pathname, parts,key)

        // console.log(key)
        setEventKey(key)
    },[])
    useEffect(()=>{
        if (eventKey != null) {
            // console.log(eventKey)
            async function getEvent(eventKey) {
                const response = await axios.get("/event/find/"+eventKey).then(res=>{
                   console.log(res.data) 
                    // if event has been found
                    if (res.data.success == true) { 
                        return res.data.event
                    } else {
                        //return to events page
                    }
                }).catch(err=>{
                    return err
                })

                return response
            }
    
            const response = getEvent(eventKey).then((result)=>{
                if (result != null) {
                    setEvent(result)
                    didMount(true)
                } else {
                    console.log(result)
                    //navigate("/events")
                }

                
            }).catch(err=>{
                console.log(err)
            })

            
        }

        
    }, [eventKey])

    useEffect(()=>{
        if (isMounted) {
            isLoading(false)
        }
    }, [isMounted])

    if (!loading) return (
        <Container className='mt-150'>
            <Row className='mb-50 row-min-h800'>
                <Col></Col>
                <Col xs={2} sm={4}>
                    <Image 
                        style={{border: "1px solid rgba(0, 0, 0, 0.175)", borderRadius: '0.375rem', height: "100%"}}
                        src= { window.location.origin + '/event/images/' + event.poster_url } fluid />
                </Col>
                <Col xs={8} sm={6} >
                    <Card className='single-event-card'>
                        <Card.Body>
                            <h2> 
                               { event.title }
                            </h2>

                            <h6> Time: {event.date + ', ' + event.time + ' ' + event.timezone}  </h6>
                            
                        <Card.Text>
                            { event.description }
                            <br/>
                            <br/>
                                <small > 
                                <span style={{ fontWeight: "bold"}}> Location: </span> {event.location} </small>
                            

                            
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>

        </Container>
        )
    

}