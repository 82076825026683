import { createContext, useState, useEffect } from 'react'; 
import { } from '../tools/main';

const AuthContext = createContext({ });
export const AuthProvider =  ({ children }) => {
    const [auth, setAuth] = useState(null)
    const [session, setSession] = useState(null)
    const [loading, isLoading] = useState(true)
    const [isMounted, didMount] = useState(false)
    useEffect(()=> {
        didMount(true)
    }, [])
    useEffect(()=> {
        if (isMounted) {
            isLoading(false)
            
        }
    }, [isMounted])

    if (!loading) return (
        <AuthContext.Provider value={{ auth, setAuth, session, setSession}}>
            { children }
        </AuthContext.Provider>
    )
}
export default AuthContext; // not AuthProviderr