import React, { useEffect, useState, useContext } from 'react';
import AuthContext from '../../context/AuthProvider';
import moment from 'moment-timezone';
import { Container, Row, Col, Card, Button, Nav, NavDropdown, Modal, Image, ListGroup, Navbar, Form, FormGroup, Alert, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import 'react-calendar/dist/Calendar.css';

import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import axios from 'axios';
import SingleEvent from './singleevent.component';


import {test, strToDateObject, compareEventDate } from '../../tools/main'
import CsamCalendar from '../calendar/csam-calendar';
import Calendar from 'react-calendar';
const timeZones = moment.tz.names();

export default function EventsPage() {
    const {auth, setAuth} = useContext(AuthContext)
    const navigate = useNavigate()

    
    //events in view
    const [allEvents, setAllEvents] = useState([])

    const [events, setEvents] = useState([])
    const [seed, setSeed] = useState(1);
    const dateToday = new Date()
    const nextMonth = new Date()
    nextMonth.setMonth(dateToday.getMonth() + 1)
    const [errMsg, setErrMsg] = useState('')
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [hideCalendar, showCalendar] = useState(true)
    const [poster, setPoster] = useState(null)

    //previous events
    const [pastEvents, setPastEvents] = useState([])
    // State to store form data
    const [bodyData, setBodyData] = useState({
        title: '',
        date: '',
        time: '',
        timezone: 'EST',
        location: '',
        description: '',
        category: '',
        author: auth.displayname,
        poster_url: '',
    });

    const [formComplete, setFormComplete] = useState(false)
    //event poster
    const [eventPoster, setEventPoster] = useState(null);

    
    const [value, setValue] = useState(dayjs('2022-04-07'));


    // test()
    const [newEvent, createEvent] = useState({})
    const [showCreateEvent, setShowCreateEvent] = useState(false)
    

    // console.log(allTimezones)
    useEffect(()=>{
        async function getAllEvents() {
            const result = await axios.post("/event/all", {}).then(res=> {
                return res
            }).catch((err) => {
                setErrMsg("Errors retrieving events.")
                return null
            })
            return result
        }

        const response =  getAllEvents().then((res)=> {
            if (res.data.success) {
                // console.log(res.data)
                const eventsArray = res.data.events
                //sort, most recent events at the start of array
                console.log(eventsArray)
                eventsArray.sort(function(a,b){ 
                    return new Date(a.date) - new Date(b.date)
                })

                console.log(eventsArray)
                setAllEvents(eventsArray)
                setEvents(eventsArray)
                setErrMsg("")

                didMount(true)
            } else {
                throw new Error('Error!')
            }
            
            
            }).catch(err=> {
                console.log(err)
                setErrMsg("Not able to retrieve events. Please try again later.")
            }
        )

        

    },[seed])

    useEffect(()=> {
        if (isMounted){
            isLoading(false)
        }
    }, [isMounted])

    useEffect(()=> {
        console.log(auth)
    },[])

    useEffect(()=> {
        if (!loading && 
            poster != null && 
            bodyData.poster_url != '' && 
            bodyData.title != '' && 
            bodyData.description != '' && 
            bodyData.time != '' && 
            bodyData.location != '' && 
            bodyData.timezone != '' && 
            bodyData.date != '') {
            setFormComplete(true)
            setErrMsg("")
        } else {
            setFormComplete(false)
            // setErrMsg("Please complete the event form.")
        }
        
        // console.log(bodyData)
    },[bodyData])



    // returns 
    const changeDateFormate = (inputDate) => {
        const event_date_object = strToDateObject(inputDate)
        const event_date_array = (event_date_object.toDateString()).split(" ")
       
        const yearUTC = event_date_array[3]
        const monthUTC = event_date_array[1] + ' ' + event_date_array[2]
        
        const formatted_date = monthUTC + ', ' + yearUTC
        // let formatted_date = time + ' ' + timezone  + ' | ' + year + '/' + month + '/' + day

        // const formattedDate = date.toDateString();


        return formatted_date

    }

    const handleDelete = async (e) => {
        e.preventDefault()
        const poster_url= e.target.id
        
        // console.log(poster_url)
        //post request to delete
        try {
            axios.delete("/event/delete/" + poster_url).then((res)=> {
                if (res.data.success == true ) {
                    console.log(res.data)
                    //reload component
                    //re-fetch events data
                    setSeed(Math.random())
                } else {
                    setErrMsg("Not able to delete event at this moment. Please try again later.")
                }
            }).catch(err=>{
                throw err
            })
        }catch(err) {
            console.log(err)
        }
        
    }

    const editView = async (e) => {
       
        //get event identifier
        const event_id = e.target.id;
        // find event
        const event = events.find((x)=> x.poster_url == event_id)
        console.log(event)
        //set data to the event's details
        setBodyData({
            title: event.title,
            date: '',
            time: '',
            timezone: 'EST',
            location: event.location,
            description: event.description,
            category: event.category,
            author: auth.displayname,
            poster_url: event.poster_url,
        })

        //display the edit view
        setShowCreateEvent(true)

    }

    const handleFileUpload = (e) => {
        const { name, value } = e.target
        
        //get the file bits
        const posterFile = e.target.files[0];
        //rename the poster
        const newPosterFileName = Date.now()

        //create new file object w/ renamed poster
        const newPosterFile = new File ([posterFile], newPosterFileName, {
            type: posterFile.type
        })

        //store new name in bodyDate
        setBodyData({
            ...bodyData,
            poster_url: newPosterFileName
          });


        setPoster(newPosterFile)
    }

    

    // Handle user input
    const handleChange = (e) => {
        const { name, value } = e.target;
        setBodyData({
          ...bodyData,
          [name]: value,
        });
      };

    const filterEventsBy = (filter) => {
        setShowCreateEvent(false)
        if ( filter == "today"){

            let events_day_array = []

            allEvents.map(event=> {
                const eventDate = strToDateObject(event.date).getUTCDate()
                const eventMonth = strToDateObject(event.date).getUTCMonth()
                const eventYear  = strToDateObject(event.date).getFullYear()
                if ( eventDate == dateToday.getDay() && eventMonth == dateToday.getUTCMonth() && eventYear == dateToday.getFullYear()) {
                    events_day_array.push(event)
                }
            })
            const x = events_day_array
            console.log(x)
            setEvents(x)

        } else if (filter == "upcoming") {  

            let temp_array = []
            allEvents.map(event => {
                if (compareEventDate(event.date) != 'Past') {
                    temp_array.push(event)
                } else {
                    console.log(event.date)
                }
            })

            setEvents(temp_array)
        } else if (filter == "this week") {
            let events_week_array = []

            allEvents.map(event=> {
                const eventWeek = strToDateObject(event.date).getUTCDay()
                const eventMonth = strToDateObject(event.date).getUTCMonth()
                const eventYear  = strToDateObject(event.date).getFullYear()
                
               
                console.log(eventWeek, dateToday.getUTCDay(), event.eventDate, dateToday)

                if ( eventWeek == dateToday.getUTCDay() && eventMonth == dateToday.getUTCMonth() && eventYear == dateToday.getFullYear()) {

                    events_week_array.push(event)
                    // console.log("new", event)
                }

            })
            setEvents(events_week_array)
        } else if (filter == "this month") {
            let events_array = []
            allEvents.map(event=> {
                const eventMonth = strToDateObject(event.date).getUTCMonth()
                const eventYear  = strToDateObject(event.date).getFullYear()
                if (eventMonth == dateToday.getUTCMonth() && eventYear == dateToday.getFullYear()) {
                    events_array.push(event)
                }

            })
            setEvents(events_array)
        } else if (filter == "next month") {
            let events_next_month = []
            allEvents.map(event => {
                const eventMonth = strToDateObject(event.date).getUTCMonth()
                const eventYear  = strToDateObject(event.date).getFullYear()
                
                console.log(dateToday, eventMonth)
                if ( ((eventMonth - dateToday.getUTCMonth() ) == 1 && eventYear == dateToday.getFullYear())  ) {
                    
                    events_next_month.push(event)
                }

            })

            setEvents(events_next_month)
        } else {
            setEvents(allEvents)
            // console.log("show all events")
        }
    }
    const handleSubmit = (e) => {
            e.preventDefault();
            // Send form data to server or perform desired action
            let formData = new FormData();

            try {
                // console.log(poster)

                formData.append("bodyData", JSON.stringify(bodyData))
                formData.append("poster", poster)

                axios.post("/event/add", formData,{
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                }).then(res=>{
                    // 
                    if (res.data.success == true ) {
                        setBodyData({
                            title: '',
                            date: '',
                            time: '',
                            timezone: 'EST',
                            location: '',
                            description: '',
                            category: '',
                            author: auth.displayname,
                            poster_url: '',
                        })

                        setPoster(null)
                        setFormComplete(false)
                        //hide create efvent
                        setShowCreateEvent(!showCreateEvent)

                        window.location.reload()
                    }
                }).catch(error =>{
                    throw error
                })
            } catch(err) {
                console.log(err)
            }

        };

    if (!loading) return(
        <Container fluid className='mt-80'>
            <Row className= "mt-3">
                <Col>
                </Col>
                <Col sm={8}>
                    { errMsg != '' ? (<Alert variant='danger'> {errMsg} </Alert>): (<></>)}
                </Col>
                <Col>
                </Col>
            </Row>
            <Row className="mt-5">
            <Row className='max-width-960px' style={{ margin: "auto"}}> 

                    <Row style={{}}>
                        <Col sm={3}>
                            <Form.Select 
                                className='events-menu-items' 
                                onChange = {e=>{filterEventsBy(e.target.value); showCalendar( hideCalendar == true ? (hideCalendar): (true)) } }>
                                <option value= "all" > Sort events</option>
                                <option value = "upcoming">Upcoming</option>
                                <option value = "all">All events</option>
                                <option value = "today">Today</option>
                                <option value = "this month">{ dateToday.toLocaleString('default', { month: 'long' }) }</option>
                                <option value = "next month">{ nextMonth.toLocaleString('default', { month: 'long' }) }</option>
                            </Form.Select>
                        </Col>



                        <Col sm={3}>
                            <Button 
                                style={{color: "black"}}
                                className='events-menu-items' 
                                variant='outline-warning'
                                onClick={e => showCalendar(!hideCalendar)}> 
                                
                                Calendar view 
                            </Button>
                        </Col>

                        <Col sm={3}>
                            { auth.accesslevel== 9 ? (<Button 
                                style={{color: "black"}} 
                                className='events-menu-items' 
                                variant='outline-warning'
                                onClick={e => setShowCreateEvent(!showCreateEvent)}> 
                                
                                Create new event
                            </Button>):(<></>)}
                        </Col>
                        
                    </Row>
            </Row>
            <Row className={ hideCalendar? ('hide'): ('show mt-3 mb-3')}>
                <Col></Col>
                <Col xs={12} sm={10}>
                    <CsamCalendar events = {[]}/>
                </Col>
                <Col></Col>
            </Row>
            <Row className= { showCreateEvent ? ("show mt-3"): ("hide")} >
                <Col></Col>
                <Col xs={12} sm={10}>
                <Card>
                    <Card.Body> 
                        <h3>Event</h3>
                    <Form onSubmit={handleSubmit} className="mt-1" encType="multipart/form-data">
                            <Form.Group controlId="title">

                            <Form.Control
                                type="text"
                                name="title"
                                placeholder='Event title'
                                value={bodyData.title}
                                onChange={handleChange}
                                required
                            />
                            </Form.Group>
                            

                            <Form.Label  className='mt-3'>Event date and time</Form.Label>
                            <Row className='mt-1'>
                                <Col>
                                    <Form.Group controlId="date">
                                    
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={bodyData.date}
                                        onChange={handleChange}
                                        required
                                    />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="time">
                                        {/* <Form.Label>Time</Form.Label> */}
                                        <Form.Control
                                            type="time"
                                            name="time"
                                            value={bodyData.time}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                                <Col>
                                    {/* <Form.Label>Timezone</Form.Label> */}
                                    <Form.Select
                                        className=''
                                        id="timezone"
                                        name="timezone"
                                        value={bodyData.timezone}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="EST">EST</option>
                                        {

                                        timeZones.map(tz => (
                                            <option value={tz}>{tz}</option>
                                        ))
                                        }
                                    </Form.Select>
                                </Col>
                     </Row>


                            <Form.Group controlId="location" className='mt-3'>
                                {/* <Form.Label>Location</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    name="location"
                                    placeholder='Location (e.g., address, zoom link, etc.)'
                                    value={bodyData.location}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="description" className='mt-3'>
                                {/* <Form.Label>Description</Form.Label> */}
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="description"
                                    placeholder="Please provide details regarding the event"
                                    value={bodyData.description}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="eventPoster" className='mt-2'>
                                <Form.Label>Event poster/image (must be image format, e.g. jpeg/jpg, png, etc.)</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="poster"
                                    placeholder='hi'
                                    accept="image/*"
                                    onChange={e=> handleFileUpload(e)}
                                    required
                                />
                            </Form.Group>
                            <Button variant="warning" type="submit" style={{ width: "15rem", }} className='mt-3'>
                                Create
                            </Button>
                        </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
            </Row>

            <Row key = { seed }  className="mt-10 event-row">
              
                <Row className='max-width-960px'>                
                    {events.map((event, index)=>{
                            return (
                            <Col xs={6} sm={4} md={3} className="mt-10 mb-1" style={{}}>  
                                <Card className='event-card' onClick={ e=> { navigate("/event-view/" + event.poster_url)}}>
                                    <div style={{ cursor: "pointer", height: ""}}  >
                                        
                                            <Card.Img 
                                            variant="top" 
                                            className="event-image" 
                                            src= { window.location.origin + '/event/images/' + event.poster_url } 
                                            />
                                        
                                    </div>
                                        <Card.Body className='event-body'>
                                            <Card.Title className='event-title' style={{ marginBottom: "2px", cursor: "pointer"}}  onClick={ e=> { navigate("/event-view/" + event.poster_url)}}> 
                                                {event.title} <br />
                                                {
                                                    compareEventDate(event.date) == 'Past' ? (<Badge bg='secondary' > Ended </Badge>):(<></>)
                                                }
                                                 
                                            </Card.Title>
                                            <div className='event-date'>
                                                {changeDateFormate (event.date) }
                                            </div>
                                            <Card.Text className='event-text'>
                                                { event.description }
                                            </Card.Text>

                                        <div className = { auth.accesslevel == 9? "mx-auto show": "hide" }>
                                            {/* <Button
                                                style={{ width: "9rem", margin: "2px"  }}
                                                variant="warning"
                                                id={event.poster_url}
                                                onClick={e=> editView(e)}> Edit
                                            </Button> */}
                                            <Button 
                                                style={{ width: "5rem", margin: "2px"  }}
                                                variant="danger"
                                                id={event.poster_url}
                                                onClick={e => handleDelete(e)}
                                                > Delete
                                            </Button>

                                            
                                            
                                        </div>
                                        </Card.Body>
                                </Card>
                            </Col>)
                        })}
                </Row>  
            </Row>

        </Container>
    )
}