import React,  {useEffect, useState, useContext } from 'react';
import AuthContext from '../../context/AuthProvider';
import Calendar from 'react-calendar';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { Calendar2Event } from 'react-bootstrap-icons';
import axios from 'axios';


function ViewListOfMatchEvents({dateClicked, matchedEvent}) {
    const navigate = useNavigate()
    // Tue Feb 13 2024 --> [Tue, Feb, 13, 2024]
    const dateClickedArray = dateClicked.toDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).split(" ")
    // [Tue, Feb, 13, 2024] ---> [Feb, 13, 2024]
    dateClickedArray.shift()
    console.log(dateClickedArray.join('-'))
    return (
        <>
            {/* <h6 style={{paddingLeft: "5px"}}>
                {dateClicked?.toDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
            </h6> */}
            <ListGroup>
            {
                matchedEvent.map((event, index)=> {
                    if (index < 3) return( 
                        <ListGroup.Item
                            style={{cursor: "pointer", paddingBottom: "0px"}}
                            className='li-event-item'
                            onClick= {e=> navigate("/event-view/" + event.poster_url)}> 
                            <h6> 
                                <Calendar2Event /> {event.title} 
                            </h6> 
                            {/* <p> {event.date.toLocaleString()}</p> */}
                            <p style={{ marginBottom: "0px"}}> {event.description} </p>
                            <small>Time: {event.date + ', ' + event.time + ' ' + event.timezone}</small>                            
                        </ListGroup.Item>
                        )
                })
            }
            <ListGroup.Item className= {matchedEvent.length < 3 ? ('hide'):('show')} style={{textAlign: 'center', paddingBottom: "0px", border: "none"}}>
                
                <Button style={{width: "150px", marginRight: "25%"}} onClick= {(e) => {navigate('/events?eventon='+dateClickedArray.join('-'))}}>
                    More
                </Button>
            </ListGroup.Item>
            </ListGroup>
        </>
    )
}
export default function CsamCalendar ({events}) {
    const {auth, setAuth} = useContext(AuthContext)
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [dateClicked, setDateClicked] = useState(null)
    const [allEvents, setEvents] = useState([])
    const [matchedEvent, setMatchedEvents] = useState([])
    const [marked, createMarkedEvents] = useState([])
    const [searchParams, setSearchParams] = useState('')
    
    useState(()=> {
        async function getAllEvents() {
            const result = await axios.post("/event/all",{}).then(res=>{
                return res
            }).catch(err=> {
                console.log(err)
                return []
            })

            return result
        }

        if (events.length == 0) {
            getAllEvents().then(res=> {
                setEvents(res.data.events)
            }).catch(err=>{
                console.log(err)
            })            
        } else {
            setEvents(events)
        }
        
        didMount(true)
        
        
    },[])

    useEffect(()=> {
        if (allEvents.length > 0) {
            let marked_array = allEvents.map((d)=> {
                let d2 = new Date(d.date)
                let d2_tz_adjusted = d2.getTime() + (d2.getTimezoneOffset() * 60000)
                let d2_adjusted_date = new Date(d2_tz_adjusted)
                return d2_adjusted_date
            })
            createMarkedEvents(marked_array)
            //Check if there is a search param
            if (window.location.search != '') {
                const x = window.location.search
                const x2 = new Date(x.split('=')[1])
                setSearchParams(x2)
            }  
  
            }
    },[allEvents])

    useEffect(()=> {
        if (isMounted) isLoading(false) 
    }, [isMounted])

    useEffect(()=> {
        //User clicked on a date, display all events on that date
        if (dateClicked != null) {
            let d_array = []
            allEvents.map(event=> {
                //creates a date obejct in utc
                const d_utc = new Date (event.date)
                const d_adjusted_to_system_timezone = d_utc.getTime() + (dateClicked.getTimezoneOffset() * 60000)
                const d = new Date(d_adjusted_to_system_timezone)
                if (
                    d.getFullYear() == dateClicked.getFullYear() 
                    && d.getMonth() == dateClicked.getMonth()
                    && d.getDate() == dateClicked.getDate() ){
                    d_array.push(event)
                }

            })

            setMatchedEvents(d_array)
        } else {
            showUpcomingEvents()
        }
    }, [dateClicked])
    
    useEffect(()=> {
        console.log(marked)
    },[marked])
    
    
    const showUpcomingEvents = function () {

    }

    if (!loading) return (
        <Row style={{ height: "auto"}}>
            <Col xs={12} sm={6} md={3} style={{ display: "inline-block", padding: "0px"}} >
                <Calendar 
                style= {{width: "100%", height: "100%", margin: 'auto'}}
                    tileClassName= {
                        ({ date, view }) => { 
                            if (view === 'month' && marked.some(eventDate => 
                                eventDate.getDate() === date.getDate() &&
                                eventDate.getMonth() === date.getMonth() &&
                                eventDate.getFullYear() === date.getFullYear())) {
                                return 'highlight-tile';
                            }
                        }
                    }
                    
                    defaultActiveStartDate = { searchParams === '' ? (new Date): (searchParams) }
                    onChange={e=> setDateClicked(e)}
                />
            </Col>
            <Col xs={12} sm={6}>
                <Card style={{border: 'none', height: '100%'}}>
                        <Card.Body style={{ padding: "0px"}}>
                        {
                            matchedEvent.length == 0 ? (<h6> No event scheduled for this date. </h6>):(<ViewListOfMatchEvents  matchedEvent = {matchedEvent}  dateClicked = {dateClicked}/>)
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}