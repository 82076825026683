
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { Linkedin, Twitter } from 'react-bootstrap-icons';

import AS_img from "./AS.png";
import TS_img from "./TS.jpeg";
import JT_img from "./JT.jpeg";
import DK_img from "./DK.jpeg";
import JS_img from "./JS.jpeg";
import HL_img from "./HL.jpg";
// import LH_img from "./JT";

export default function AboutPage() {
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)

    useEffect(()=>{
        didMount(true)
    }, [])

    useEffect(()=>{
        if (isMounted) {
            isLoading(false)
        }
    }, [isMounted])

    const board_of_directors = [
        {
            name: "Dr. Tarek Sardana",
            role: "",
            image: TS_img ,
            linkedin: "https://www.linkedin.com/in/tarek-sardana-cd-md-drcpc-fasma-2aa65a16/?originalSubdomain=ca"
        },
        {
            name: "Dr. David Kim",
            role: "",
            image: DK_img,
            linkedin: "https://www.linkedin.com/in/davidshkim1/"
        },
        {
            name: "Dr. Joelle Thorgrimson",
            role: "",
            image: JT_img,
            linkedin: "https://www.linkedin.com/in/joelle-thorgrimson-6389ba45/?trk=pub-pbmap&originalSubdomain=ca"
        },
        {
            name: "Dr. Adam Sirek",
            role: "",
            image: AS_img,
            linkedin: "https://www.linkedin.com/in/adamsirek/?originalSubdomain=ca"
        },
        {
            name: "Dr. Joan Saary",
            role: "",
            image: JS_img,
            linkedin: "https://www.linkedin.com/in/joan-saary-b06b744/?originalSubdomain=ca"
        },
        {
            name: "Dr. Heather Langille",
            role: "",
            image: HL_img,
            linkedin: "https://www.linkedin.com/in/heather-smith-langille/?originalSubdomain=ca"
        }
    ]

    if (!loading) return(
        <Container className='mt-100'>
            <Row>
                <Col></Col>
                <Col xs={8} lg={6}>
                    <Card className="mt-5" style={{ border: "none"}}>
                        <div style={{ textAlign: "center"}}>
                            <h3 className='mb-0'> About </h3>
                        </div>
                         

                        <Card.Body style={{ fontSize: "18px"}}>
                                <p>
                                The Canadian Society of Aerospace Medicine was created in 2019 
                                to be an authoritative body for matters related to aerospace medicine in Canada. 
                                The society aims to promote and cultivate the discipline of 
                                aerospace medicine and related sciences through education, outreach, and collaboration. 
                                </p>
                                <p>
                                It is an affiliate organization of the Aerospace Medical Association and is 
                                registered as a non-profit organization in Canada. With members in all 
                                sectors of aerospace medicine, the society hopes to create a 
                                supportive network for its subspecialty in Canada.
                                </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>

            <Row className="mt-5 mb-5 fade-in" id="about-div" style={{ justifyContent: "center"}}>
                
                <Col xs={10} lg={8}>
                    <Card style={{ border: "none"}}>
                        <div style={{ textAlign: "center"}}>
                            <h3> Board of Directors  </h3>
                        </div>
                         

                        <Card.Body>
                            <Row className=''>

                                {
                                    board_of_directors.map((member)=> {
                                        return(
                                            <Col xs={12} sm={6} md={4} className="mx-auto mt-3 mb-3" > 
                                                <Card className="bod-card"onClick={ (e) => { window.open(member.linkedin); }} >
                                                    <div style={{ height: "200px"}} >
                                                        <Image style={{ borderRadius: "50%",  height: "auto", width: "100%", maxWidth: "200px", maxHeight: "200px"}} src={member.image} />
                                                    </div>
                                                    
                                                    <Card.Text className='mt-2'>
                                                        <strong className=''> {member.name} </strong> <br/>
                                                        <Linkedin className='mx-1' />
                                                        {/* <Twitter /> */}
                                                    </Card.Text>
                                                </Card>
                                        
                                            </Col>
                                        )
                                    })
                                }

                            </Row>
                         
                                
                        </Card.Body>
                    </Card>
                </Col>
                
            </Row>
        </Container>
    )
}